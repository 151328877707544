import React from "react";
import CertificateSlider from "./slide";
import SlideTwo from "./slideTwo";

const Certificates = () => {
  return (
    <div>
      {/* <CertificateSlider /> */}
      <SlideTwo />
    </div>
  );
};

export default Certificates;
